import { Grid, Paper, Typography } from "@material-ui/core";
import RulesTable from "../../../commons/components/RulesTable/RulesTable";

const inquiriesOptometristColumns = [
  {
    field: "city",
    name: "Ciudad",
    type: "dropdown",
    dropdownType: "city",
  },
  {
    field: "type_of_optometrist",
    name: "Tipo de Optómetra",
    type: "dropdown",
    dropdownType: "shop",
  },
  {
    field: "consultation_commission_with_purchase",
    name: "Consultas con compra",
    type: "money",
  },
  {
    field: "consultation_commission_without_purchase",
    name: "Consultas sin compra",
    type: "money",
  },
  {
    field: "position",
    name: "Cargo",
    type: "text",
  },
];

const managementBossOptometristColumns = [
  {
    field: "customer_conversion",
    name: "Conversión de clientes",
    type: "percentage",
  },
  {
    field: "portafolio",
    name: "Cartera",
    type: "percentage",
  },
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
  {
    field: "cluster_label",
    name: "Cluster",
    type: "text",
  },
  {
    field: "position",
    name: "Cargo",
    type: "text",
  },
];

const optometristConsultations2022Columns = [
  {
    field: "consultation_commission_with_purchase",
    name: "Consultas con compra",
    type: "money",
  },
  {
    field: "consultation_commission_without_purchase",
    name: "Consultas con compra",
    type: "money",
  },
  {
    field: "consultation_level_bonus",
    name: "Bono",
    type: "money",
  },
  {
    field: "cluster_label",
    name: "Cluster",
    type: "text",
  },
  {
    field: "position",
    name: "Cargo",
    type: "text",
  },
  {
    field: "multiplier_contact_lenses",
    name: "Multiplicador lentes de contacto",
    type: "text",
  },
  {
    field: "multiplier_progressive_lenses",
    name: "Multiplicador lentes progresivos",
    type: "text",
  },
  {
    field: "multiplier_rayban",
    name: "Multiplicador RayBan",
    type: "text",
  },
];

const optometristBonus2022Columns = [
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
  {
    field: "cluster_label",
    name: "Cluster",
    type: "text",
  },
  {
    field: "position",
    name: "Cargo",
    type: "text",
  },
];

const performanceOptometristColumns = [
  {
    field: "city",
    name: "Ciudad",
    type: "dropdown",
    dropdownType: "city",
  },
  {
    field: "greater_or_lesser_store_fulfillment",
    name: "Condición para cumplimiento de tienda",
    type: "dropdown",
    dropdownType: "greaterLesser",
  },
  {
    field: "store_fulfillment",
    name: "Cumplimiento Tienda",
    type: "percentage",
  },
  {
    field: "position",
    name: "Cargo",
    type: "text",
  },
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
];

function Optometra() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Paper
        elevation={3}
        style={{
          padding: 25,
          margin: 0,
          paddingTop: 10,
          backgroundColor: "#F9D1D6",
        }}
      >
        <div style={{ height: 10 }} />
        <RulesTable
          title="Bono optómetra jefe 2022"
          columns={managementBossOptometristColumns}
          uri="ray-management-boss-optometrist"
          downloadUri="ray-management-boss-optometrist/file"
        />
        <div style={{ height: 40 }} />
        <RulesTable
          title="Consultas optómetra 2022"
          columns={optometristConsultations2022Columns}
          uri="ray-optometrist-consultations2022"
          downloadUri="ray-optometrist-consultations2022/file"
        />
        <div style={{ height: 40 }} />
        <RulesTable
          title="Bono de optómetra 2022"
          columns={optometristBonus2022Columns}
          uri="ray-optometrist-bonus2022"
          downloadUri="ray-optometrist-bonus2022/file"
        />
        <div style={{ height: 40 }} />
        <RulesTable
          title="Bonos de gestión"
          columns={performanceOptometristColumns}
          uri="ray-performance-optometrist"
          downloadUri="ray-performance-optometrist/file"
        />
        <div style={{ height: 40 }} />
        <RulesTable
          title="Valores por consulta"
          columns={inquiriesOptometristColumns}
          uri="ray-inquiries-optometrist"
          downloadUri="ray-inquiries-optometrist/file"
        />
      </Paper>

      {/* <Paper
        elevation={3}
        style={{ padding: 25, margin: 20, backgroundColor: "#F9D1D6" }}
      >
        <Typography variant="h4">
          Comisiones por bonos de rendimiento
        </Typography>
        <div style={{ height: 20 }} />
        {/* ----------------------------
             TABLA DE META PERSONAL
         -----------------------------
        <CustomParametersTable
          columns={metaPersonalColumns}
          rows={metaPersonalValues}
        />
        <div style={{ height: 40 }} />
      </Paper> */}
    </Grid>
  );
}

export default Optometra;
