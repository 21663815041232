import { Grid } from "@material-ui/core";
import { useContext, useMemo, useState } from "react";
import { UtilsContext } from "../..";
import Asesor from "./Gmo/Asesor";
import AsesorRayBan from "./RayBan/Asesor";
import ReglasPorCargoGmo from "./Gmo/ReglasPorCargo";
import JefeDeTienda from "./Gmo/JefeDeTienda";
import Optometra from "./Gmo/Optometra";
import OptometraRayBan from "./RayBan/Optometra";
import { useGmoRaybanParametersStyles } from "./useGmoRaybanParametersStyles";
import JefesSgh from "./Sgh/Jefes";
import VentasSgh from "./Sgh/Ventas";
import LimitesSgh from "./Sgh/Limites";
import ReglasPorCargoSgh from "./Sgh/ReglasPorCargo";
import JefesRayBan from "./RayBan/Jefes";
import VentasRayBan from "./RayBan/Ventas";
import LimitesRayBan from "./RayBan/Limites";
import ReglasPorCargoRayBan from "./RayBan/ReglasPorCargo";
import AsesorLfm from "./Lfm/Asesor";
import ReglasPorCargoLfm from "./Lfm/ReglasPorCargo";
import JefeDeTiendaLfm from "./Lfm/JefeDeTienda";
import OptometraLfm from "./Lfm/Optometra";


const ParametersSwitch = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useGmoRaybanParametersStyles();
  const { enterprise } = useContext(UtilsContext);

  // TODO 2025 cambiar rayban

  const tabs = useMemo(
    () => {
      setSelectedTab(0);
      if (enterprise === "gmo") return ([
        {
          id: 0,
          label: "Jefe",
          component: <JefeDeTienda />,
        },
        {
          id: 1,
          label: "Optómetra",
          component: <Optometra />,
        },
        {
          id: 2,
          label: "Asesor",
          component: <Asesor />,
        },
        {
          id: 3,
          label: "Habilitación de reglas",
          component: <ReglasPorCargoGmo />,
        }
      ]);
      else if (enterprise === "sgh") return ([
        {
          id: 0,
          label: "Ventas",
          component: <VentasSgh />,
        },
        {
          id: 1,
          label: "Jefes",
          component: <JefesSgh />,
        },
        {
          id: 2,
          label: "Limites",
          component: <LimitesSgh />,
        },
        {
          id: 3,
          label: "Habilitación de reglas",
          component: <ReglasPorCargoSgh />,
        }
      ]);
      else if (enterprise === "ray") return ([
        {
          id: 0,
          label: "Jefes",
          component: <JefesRayBan />,
        },
        {
          id: 1,
          label: "Ventas",
          component: <VentasRayBan />,
        },
        {
          id: 1,
          label: "Optómetra",
          component: <OptometraRayBan />,
        },
        {
          id: 2,
          label: "Asesor",
          component: <AsesorRayBan />,
        },
        {
          id: 3,
          label: "Habilitación de reglas",
          component: <ReglasPorCargoRayBan />,
        }
      ]);
      else if (enterprise === "lfm") return ([
        {
          id: 0,
          label: "Jefe",
          component: <JefeDeTiendaLfm />,
        },
        {
          id: 1,
          label: "Optómetra",
          component: <OptometraLfm />,
        },
        {
          id: 2,
          label: "Asesor",
          component: <AsesorLfm />,
        },
        {
          id: 3,
          label: "Habilitación de reglas",
          component: <ReglasPorCargoLfm />,
        }
      ]);
      else return <></>;
    },
    [enterprise]
  );

  return (
    <Grid>
      <Grid container direction="row">
        {tabs.map((tab, i) => (
          <Grid
            key={i}
            onClick={() => setSelectedTab(i)}
            className={
              selectedTab === i ? classes.selectedTab : classes.nonSelectedTab
            }
          >
            {tab.label}
          </Grid>
        ))}
      </Grid>
      <Grid style={{ maxWidth: "90vw" }} container>
        {tabs[selectedTab]?.component}
      </Grid>
    </Grid>
  );
}

export default ParametersSwitch;
