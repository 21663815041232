import { Grid, Paper } from "@material-ui/core";
import RulesTable from "../../../commons/components/RulesTable/RulesTable";

const managementBossColumns = [
  {
    field: "city",
    name: "Ciudad",
    type: "dropdown",
    dropdownType: "city",
  },
  {
    field: "type_of_boss",
    name: "Tipo de Jefe",
    type: "dropdown",
    dropdownType: "typeOfBoss",
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "sales_compliance",
    name: "Cumplimiento de Ventas",
    type: "percentage",
  },
  {
    field: "mermas",
    name: "Mermas",
    type: "percentage",
  },
  {
    field: "customer_conversion",
    name: "Conversión Clientes",
    type: "percentage",
  },
  {
    field: "proposed_activities",
    name: "Tablero de Actividades",
    type: "percentage",
  },
  {
    field: "portafolio",
    name: "Cartera",
    type: "percentage",
  },
  {
    field: "add_product_greater_than",
    name: "Condición Sumaproducto",
    type: "percentage",
  },
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
];

const managementZBossColumns = [
  {
    field: "id",
    name: "Cédula",
    type: "text"
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "sales_compliance",
    name: "Cumplimiento de Ventas",
    type: "percentage",
  },
  {
    field: "mermas",
    name: "Mermas",
    type: "percentage",
  },
  {
    field: "customer_conversion",
    name: "Conversión Clientes",
    type: "percentage",
  },
  {
    field: "proposed_activities",
    name: "Tablero de Actividades",
    type: "percentage",
  },
  {
    field: "inquiries",
    name: "Consultas",
    type: "percentage",
  },
  {
    field: "add_product_greater_than",
    name: "Condición Sumaproducto",
    type: "percentage",
  },
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
];

const clusterBossColumns = [
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "cluster_label",
    name: "Clúster",
    type: "text"
  },
  {
    field: "isActual",
    name: "Estado",
    type: "bool"
  },
];

const clusterGoalBossColumns = [
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
  {
    field: "cluster_label",
    name: "Clúster",
    type: "text"
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "isActual",
    name: "Estado",
    type: "bool"
  },
];

const clusterOperatingBossColumns = [
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
  {
    field: "cluster_label",
    name: "Clúster",
    type: "text"
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "isActual",
    name: "Estado",
    type: "bool"
  },
];

// Variables bono operativo
const clusterOperatingBonusColumns = [
  {
    field: "portafolio",
    name: "Venta facturada",
    type: "percentage",
  },
  {
    field: "conversionCustomer",
    name: "Conversión clientes",
    type: "percentage"
  },
  {
    field: "activities",
    name: "Actividades",
    type: "percentage"
  },
  {
    field: "mermas",
    name: "Mermas",
    type: "percentage"
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "isActual",
    name: "Estado",
    type: "bool"
  }
];

function JefeDeTienda() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" style={{width: "100%"}}>
      <Paper
        elevation={3}
        style={{
          padding: 25,
          paddingTop: 0,
          backgroundColor: "#DEEDF6",
        }}
      >
        <div style={{ height: 40 }} />
        <RulesTable
          title="Variables Bono Operativo"
          columns={clusterOperatingBonusColumns}
          uri="lfm-cluster-operating-variable-boss"
          downloadUri="lfm-cluster-operating-variable-boss/file"
        />

        <div style={{ height: 40 }} />
        <RulesTable
          title="Bonos Operativo"
          columns={clusterOperatingBossColumns}
          uri="lfm-cluster-operating-boss"
          downloadUri="lfm-cluster-operating-boss/file"
        />

        <div style={{ height: 40 }} />
        <RulesTable
          title="Bonos de Meta Jefe"
          columns={clusterGoalBossColumns}
          uri="lfm-cluster-goal-boss"
          downloadUri="lfm-cluster-goal-boss/file"
        />

        {/* TODO 2025 descomentar esto */}
        {/* <div style={{ height: 40 }} />
        <RulesTable
          title="Bonos de Jefe de Clúster"
          columns={clusterBossColumns}
          uri="lfm-cluster-boss"
          downloadUri="lfm-cluster-boss/file"
        /> */}

        {/* <div style={{ height: 10 }} />
        <RulesTable
          title="Bonos de gestión"
          columns={managementBossColumns}
          uri="lfm-management-boss"
          downloadUri="lfm-management-boss/file"
        /> */}

        {/* <div style={{ height: 40 }} />
        <RulesTable
          title="Bonos de gestión Jefe Zonal"
          columns={managementZBossColumns}
          uri="lfm-management-zboss"
          downloadUri="lfm-management-zboss/file"
        /> */}
        {/* <div style={{ height: 10 }} /> */}

        {/*         
        <CustomDialog
          title="Editar bono de gestión"
          open={openGestionDialog}
          handler={() => setOpenGestionDialog(false)}
        >
          <FormControl fullWidth sx={{ m: 1 }}>
            <CurrencyElement />
          </FormControl>
        </CustomDialog> */}
      </Paper>
    </Grid>
  );
}

export default JefeDeTienda;
