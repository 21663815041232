import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FETCH_URL } from "../../../config";
import useTokenManager from "../../../hooks/useTokenManager";
import CustomAutocomplete from "./CustomAutocomplete";

const utils = {
  percentage: { type: "number", formatType: "float", step: 0.01 },
  money: { type: "number", formatType: "float", step: 1000 },
  text: { type: "text", formatType: null, step: null },
  date: { type: "date", formatType: "date", step: null },
};

const autocompleteUtils = {
  city: { uri: "store/cities/", defaultValue: "BUCARAMANGA" },
  typeOfBoss: {
    uri: "gmo-management-boss/typesOfBoss",
    defaultValue: "JEFE TIENDA 1",
  },
  position: {
    uri: "employees/positions",
    defaultValue: "POSICIÓN 1",
  },
  shop: {},
  greaterLesser: {},
};

const options = {
  shop: ["1", "2", "3", "A", "B", "C"],
  greaterLesser: ["mayor", "menor"],
};



function RuleDetailForm({ ruleId, refresh, columns, close, uri, add }) {
  const [info, setInfo] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setIsLoading] = useState(true);
  const { getToken } = useTokenManager();

  // useEffect(() => console.info("Cambióó", uri), [uri])
  // useEffect(() => console.info("Cambióó info", info), [info])
  // useEffect(() => console.info("Cambióó add", add), [add])

  const parseDate = useMemo(() => (date) => {
    if (!date) return "";

    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }, [formData]);

  const onValueChange = (key, value, type) => {
    console.info("Cambiooo", key, value, type);
    setFormData((prevState) => ({
      ...prevState,
      [key]: type === "str" ? value : type === "bool" ? value : type === "date" ? parseDate(value) : parseFloat(value)
    }));
  };

  const saveNewRule = useCallback(() => {
    const newRule = {}

    // Inits a template for creating the new rule
    columns.forEach((rule) => {
      if (rule.type === "dropdown") {
        const { defaultValue } = autocompleteUtils[rule.dropdownType];
        newRule[rule.field] = defaultValue;
      }
      else if (rule.type === "percentage") {
        newRule[rule.field] = 0.0;
      }
      else if (rule.type === "money") {
        newRule[rule.field] = 0.0;
      }
      else if (rule.type === "bool") {
        newRule[rule.field] = false;
      }
      else {
        newRule[rule.field] = "";
      }
    });

    // Overrides values available in formData
    for (var key of Object.keys(formData)) {
      newRule[key] = formData[key];
    }

    // Resulting rule before sending
    // console.info("New Rule:")
    // console.info(newRule);

    const jwt = getToken();

    // alert(`${FETCH_URL}${uri}`);
    // alert(JSON.stringify(columns));
    // alert(JSON.stringify(formData));
    // alert(JSON.stringify({
    //   ...newRule,
    //   city: newRule.city ? newRule.city.toUpperCase() : undefined,
    // }));

    fetch(`${FETCH_URL}${uri}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + jwt
      },
      body: JSON.stringify({
        ...newRule,
        city: newRule.city ? newRule.city.toUpperCase() : undefined,
      }),
    }).then(() => {
      // console.info("sent!")
      close();
      setInfo({});
      refresh();
    }).catch((error) => console.error(error))
  }, [formData, getToken, refresh]);


  const modifyExistentRule = () => {
    // Take fetched info as the template
    const newRule = {
      ...info,
      id: null,
      isActual: null
    };

    // Overrides values available in formData
    for (var key of Object.keys(formData)) {
      newRule[key] = formData[key];
    }

    const parsedRule = {};

    for (var key of Object.keys(newRule)) {
      if(newRule[key]) {
        parsedRule[key] = newRule[key];
      }
    }

    // console.info("Form Data:")
    // console.info(formData);

    delete parsedRule["initial_date"];

    const jwt = getToken();

    fetch(`${FETCH_URL}${uri}/${ruleId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + jwt
      },
      body: JSON.stringify(parsedRule),
    }).then(() => {
      // console.info("sent!")
      close();
      setInfo({});
      refresh();
    }).catch((error) => console.error(error))
  };

  useEffect(() => {
    if (ruleId) {
      const jwt = getToken();
      fetch(`${FETCH_URL}${uri}/${ruleId}`, { headers: { "Authorization": "Bearer " + jwt } })
        .then((response) => response.json())
        .then((d) => {
          console.info(d);
          setInfo({...d, initial_date: parseDate(d.initial_date), final_date: parseDate(d.final_date)});
          setIsLoading(false);
          // console.info("Entró al useEffect de inicio");
          // console.info(d);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {columns.map((rule, i) => {
              if (rule.type === "dropdown") {
                const { uri, defaultValue } =
                  autocompleteUtils[rule.dropdownType];

                return (
                  <Grid item style={{ margin: 15 }}>
                    <CustomAutocomplete
                      uri={uri}
                      customOptions={options[rule.dropdownType]}
                      defaultValue={info[rule.field] ?? defaultValue}
                      value={formData[rule.field]}
                      setValue={(newValue) =>
                        onValueChange(rule.field, newValue, rule.type)
                      }
                      label={rule.name}
                      id={rule.dropdownType}
                    />
                  </Grid>
                );
              } else if (rule.type === "bool") {
                return (
                  <Grid item style={{ margin: 15, width: 230 }}>
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Switch
                            defaultChecked={info[rule.field]}
                            value={formData[rule.field]}
                            onChange={() => onValueChange(rule.field, !!!formData[rule.field], "bool")}
                          />
                        )}
                        label={rule.name}
                      />
                    </FormGroup>
                  </Grid>
                );
              } 
              else if (!rule || !rule.type) {
                return <></>;
              }
              else {
                // alert(rule.type);
                const { type, formatType, step } = utils[rule.type];

                return (
                  <Grid item style={{ margin: 15, width: 230 }}>
                    <TextField
                      fullWidth
                      id={rule.field}
                      type={type}
                      inputProps={{ step }}
                      label={rule.name}
                      defaultValue={info[rule.field] ?? 0.0}
                      variant="filled"
                      value={formData[rule.field]}
                      onChange={(event) =>
                        onValueChange(
                          event.target.id,
                          event.target.value,
                          formatType
                        )
                      }
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {/* <TextField
              required
              id="mermas"
              type="number"
              inputProps={{ step: 0.01 }}
              label="Mermas"
              defaultValue={info.mermas ?? 0.0}
              variant="filled"
              value={formData.mermas}
              onChange={(event) =>
                onValueChange(event.target.id, event.target.value, "float")
              }
            />
            <TextField
              required
              id="customer_conversion"
              type="number"
              inputProps={{ step: 0.01 }}
              label="Conversión de clientes"
              defaultValue={info.customer_conversion ?? 0.1}
              variant="filled"
              value={formData.customer_conversion}
              onChange={(event) =>
                onValueChange(event.target.id, event.target.value, "float")
              }
            />
            <TextField
              required
              id="proposed_activities"
              type="number"
              inputProps={{ step: 0.01 }}
              label="Actividades propuestas"
              defaultValue={info.proposed_activities ?? 0.15}
              variant="filled"
              value={formData.proposed_activities}
              onChange={(event) =>
                onValueChange(event.target.id, event.target.value, "float")
              }
            />
            <TextField
              required
              id="portafolio"
              type="number"
              inputProps={{ step: 0.01 }}
              label="Portafolio"
              defaultValue={info.portafolio ?? 0.15}
              variant="filled"
              value={formData.portafolio}
              onChange={(event) =>
                onValueChange(event.target.id, event.target.value, "float")
              }
            />
            <TextField
              required
              id="add_product_greater_than"
              type="number"
              inputProps={{ step: 0.01 }}
              label="Mayor que este valor"
              defaultValue={info.add_product_greater_than ?? 0.15}
              variant="filled"
              value={formData.add_product_greater_than}
              onChange={(event) =>
                onValueChange(event.target.id, event.target.value, "float")
              }
            />
            <CustomAutocomplete
              uri="store/cities/"
              defaultValue={info.city ?? "BUCARAMANGA"}
              value={city}
              setValue={setCity}
              label="Ciudad"
              id={"city"}
            />
            <CustomAutocomplete
              uri="gmo-management-boss/typesOfBoss"
              defaultValue={info.bossType ?? "JEFE TIENDA 1"}
              value={bossType}
              setValue={setBossType}
              label="Tipo de Jefe"
              id={"type_of_boss"}
            />
            <Autocomplete
              disablePortal
              defaultValue="JEFE TIENDA 1"
              variant="filled"
              id="type_of_boss"
              label="type_of_boss"
              onClose={() => {
                console.info("Clic en cerrar");
                setCity(city);
              }}
              options={bossTypes}
              onChange={(event, value) => {
                setBossType(value);
              }}
              sx={{ width: 220 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de jefe"
                  value={bossType}
                  onChange={(event) => {
                    setCity(event.target.value);
                  }}
                />
              )}
            />
            <TextField
              id="position"
              label="Cargo"
              defaultValue={info.position ?? "ENCARGADOS"}
              variant="filled"
              value={formData.position}
              onChange={(event) =>
                onValueChange(event.target.id, event.target.value)
              }
            />
            <TextField
              required
              id="bonus"
              type="number"
              inputProps={{ step: 0.01 }}
              label="Bono"
              defaultValue={info.bonus ?? 100}
              variant="filled"
              value={formData.bonus}
              onChange={(event) =>
                onValueChange(event.target.id, event.target.value, "float")
              }
            /> */}
            <Grid item style={{ marginTop: 15 }}>
              <Button
                onClick={add ? saveNewRule : modifyExistentRule}
                variant="contained"
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default RuleDetailForm;
