import { Grid } from "@material-ui/core";

const ConsultationDetailCard = ({
  storeName,
  storeDetail,
  color,
  withPurchase,
  withoutPurchase,
  contactLenses,
  progressiveLenses,
  adaptations,
  subscriptions
}) => {
  return (
    <Grid
      style={{
        minHeight: "133px",
        width: "100%",
        padding: 10,
        backgroundColor: color,
        borderRadius: 15
      }}
    >
      <Grid item style={{ minHeight: 50 }}>
        <p
          style={{
            margin: 0,
            padding: 0,
            fontWeight: 600,
            fontSize: 23,
          }}
        >
          {storeName}
          <br />
          {storeDetail}
        </p>
      </Grid>
      <Grid item>
        <p
          style={{
            margin: 0,
            padding: 0,
            textAlign: "left",
            fontSize: 18,
            top: 0,
            right: -5,
          }}
        >
          <span style={{ fontWeight: 500 }}> Consultas con compra:</span>{" "}
          {withPurchase}
          <br />
          <span style={{ fontWeight: 500 }}> Consultas sin compra:</span>{" "}
          {withoutPurchase}
          <br />
          <span style={{ fontWeight: 500 }}> Lentes de contacto:</span>{" "}
          {contactLenses ?? "N/A"}
          <br />
          <span style={{ fontWeight: 500 }}> Lentes progresivos:</span>{" "}
          {progressiveLenses ?? "N/A"}
          <br />
          <span style={{ fontWeight: 500 }}> Adaptaciones:</span>{" "}
          {adaptations ?? "N/A"}
          <br />
          <span style={{ fontWeight: 500 }}> Suscripciones:</span>{" "}
          {subscriptions ?? "N/A"}
        </p>
      </Grid>
    </Grid>
  );
};

export default ConsultationDetailCard;
