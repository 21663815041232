import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CommisionDetailCard from "./CommisionDetailCard";
import StoreCard from "./StoreCard";
import ConsultationDetailCard from "../ConsultationDetailCard/ConsultationDetailCard";
import { FETCH_URL } from "../../config";
import { Button, Grid, Paper } from "@material-ui/core";
import useTokenManager from "../../hooks/useTokenManager";
import { translate } from "../../commons/translators";

const specificColors = [
  "#EFFCEF",
  "#F9D1D6",
  "#F9D1D6",
  "#EFFCEF",
  "#EFFCEF",
  "#F9D1D6",
  "#F9D1D6",
  "#EFFCEF",
  "#EFFCEF",
  "#F9D1D6",
  "#F9D1D6",
  "#EFFCEF",
];

const CommisionDetailGMO = ({ user }) => {
  const [optometristConsultations, setOptometristConsultations] = useState();
  const { getToken } = useTokenManager();
  const [isLoading, setIsLoading] = useState(true);
  const [labels, setLabels] = useState({});

  const fetchLabelNames = () => {
    const jwt = getToken();
    fetch(`${FETCH_URL}names`, { headers: { Authorization: "Bearer " + jwt } })
      .then((responseNames) => responseNames.json())
      .then((columnNames) => {
        // console.log({columnNames});
        setLabels(columnNames);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {console.log({user})}, []);

  useEffect(() => {
    fetchLabelNames();

    if (
      user?.gmo_rules_for_charge_id?.gmo_inquiries_optometrist ||
      user?.gmo_rules_for_charge_id?.gmo_inquiries_optometrist2022
    ) {
      const jwt = getToken();
      fetch(
        `${FETCH_URL}optometrist-consultatitions?month=${user.month}&year=${user.year}&idEmpleado=${user.employee.id_document}`,
        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwt,
          },
        }
      )
        .then((response) => response.json())
        .then((fetchedData) => {
          console.info("OP CONSULTATIONS", fetchedData);
          setOptometristConsultations(fetchedData);
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <>
      {/* BLOQUE DE COMISIÓN POR VENTAS DEL ASESOR */}
      {user?.gmo_rules_for_charge_id?.gmo_sales_advisor ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">
                <strong>
                  {translate(
                    "gmo",
                    "Comisión por ventas",
                    labels["gmo-sales-advisor"]?.personal_goal_percentage
                      ?.actualName
                  )}
                </strong>
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ flexGrow: 1 }} style={{ marginTop: 20 }}>
                <Grid container spacing={2}>
                  {[
                    {
                      title: translate(
                        "gmo",
                        "Ventas",
                        labels["gmo-sales-advisor"]?.personal_goal_percentage
                          ?.actualName
                      ),
                      value: user.employee.monthlyEmployees[0].sales,
                      valueType: "money",
                    },
                    {
                      title: translate(
                        "gmo",
                        "Meta de ventas",
                        labels["gmo-sales-advisor"]?.personal_goal_percentage
                          ?.actualName
                      ),
                      value: user.employee.monthlyEmployees[0].sales_goal,
                      valueType: "money",
                    },
                    {
                      title: translate(
                        "gmo",
                        "Porcentaje de cumplimiento",
                        null
                      ),
                      value:
                        user.employee.monthlyEmployees[0]
                          .personal_sales_compliance_percentage,
                      valueType: "percentage",
                    },
                    {
                      title: translate(
                        "gmo",
                        "Comisión de ventas del asesor",
                        labels["gmo-sales-advisor"]?.personal_goal_percentage
                          ?.actualName
                      ),
                      value: user.advisor_sales_commission,
                      valueType: "money",
                    },
                  ].map(({ title, value, valueType }, index) => (
                    <Grid container item xs={3}>
                      <CommisionDetailCard
                        title={title}
                        color={index % 2 !== 0 ? "#F9D1D6" : "#EFFCEF"}
                        value={value}
                        valueType={valueType}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {/* BLOQUE DE COMISIÓN POR VENTAS DEL ASESOR 2024 */}
      {user?.gmo_rules_for_charge_id?.gmo_sales_advisor2024 ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">
                <strong>
                  {translate(
                    "gmo",
                    "Comisión por ventas",
                    labels["gmo-sales-advisor"]?.personal_goal_percentage
                      ?.actualName
                  )}
                </strong>
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ flexGrow: 1 }} style={{ marginTop: 20 }}>
                <Grid container spacing={2}>
                  {[
                    {
                      title: translate(
                        "gmo",
                        "Ventas",
                        labels["gmo-sales-advisor"]?.personal_goal_percentage
                          ?.actualName
                      ),
                      value: user.employee.monthlyEmployees[0].sales,
                      valueType: "money",
                    },
                    {
                      title: translate(
                        "gmo",
                        "Meta de ventas",
                        labels["gmo-sales-advisor"]?.personal_goal_percentage
                          ?.actualName
                      ),
                      value: user.employee.monthlyEmployees[0].sales_goal,
                      valueType: "money",
                    },
                    {
                      title: translate(
                        "gmo",
                        "Porcentaje de cumplimiento",
                        null
                      ),
                      value:
                        user.employee.monthlyEmployees[0]
                          .personal_sales_compliance_percentage,
                      valueType: "percentage",
                    },
                    {
                      title: translate(
                        "gmo",
                        "Comisión de ventas del asesor",
                        labels["gmo-sales-advisor"]?.personal_goal_percentage
                          ?.actualName
                      ),
                      value: user.advisor_sales_commission,
                      valueType: "money",
                    },
                  ].map(({ title, value, valueType }, index) => (
                    <Grid container item xs={3}>
                      <CommisionDetailCard
                        title={title}
                        color={index % 2 !== 0 ? "#F9D1D6" : "#EFFCEF"}
                        value={value}
                        valueType={valueType}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {/* BLOQUE DE Comisión por rendimiento de tienda para el asesor: */}
      {user?.gmo_rules_for_charge_id?.gmo_performance_advisor ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 50 }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">
                <strong>
                  {translate(
                    "gmo",
                    "Comisión por rendimiento de tienda",
                    labels["gmo-performance-advisor"]?.mermas?.actualName +
                      "|" +
                      labels["gmo-performance-advisor"]?.store_fulfillment
                        ?.actualName
                  )}
                </strong>
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ flexGrow: 1 }} style={{ marginTop: 20 }}>
                <Grid container spacing={2}>
                  {[
                    {
                      title: translate(
                        "gmo",
                        "Porcentaje de cumplimiento de ventas",
                        labels["gmo-performance-advisor"]
                          ?.personal_goal_percentage?.actualName
                      ),
                      value:
                        user.employee.monthlyEmployees[0]
                          .personal_sales_compliance_percentage,
                      valueType: "percentage",
                    },
                    {
                      title: translate(
                        "gmo",
                        "Porcentaje de ventas de la tienda",
                        labels["gmo-performance-advisor"]?.store_fulfillment
                          ?.actualName
                      ),
                      value:
                        user.employee.store_codes[0].monthlyStores[0]
                          .sales_compliance_percentage,
                      valueType: "percentage",
                    },
                    {
                      title: translate(
                        "gmo",
                        "Porcentaje de mermas admitidas",
                        labels["gmo-performance-advisor"]?.store_fulfillment
                          ?.actualName
                      ),
                      value:
                        user.employee.store_codes[0].monthlyStores[0]
                          .mermas_converted_percentage,
                      valueType: "percentage",
                    },
                    {
                      title: translate(
                        "gmo",
                        "Bono de rendimiento de tienda",
                        null
                      ),
                      value: user.advisor_performance_bonus,
                      valueType: "money",
                    },
                  ].map(({ title, value, valueType }, index) => (
                    <Grid container item xs={3}>
                      <CommisionDetailCard
                        title={title}
                        color={index % 2 === 0 ? "#F9D1D6" : "#EFFCEF"}
                        value={value}
                        valueType={valueType}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {/* BLOQUE DE gmo_cluster_performance_store_advisor 2024: */}
      {user?.gmo_rules_for_charge_id?.gmo_cluster_performance_store_advisor ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 50 }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">
                <strong>
                  {translate(
                    "gmo",
                    "Comisión por rendimiento de tienda",
                    labels["gmo-performance-advisor"]?.mermas?.actualName +
                      "|" +
                      labels["gmo-performance-advisor"]?.store_fulfillment
                        ?.actualName
                  )}
                </strong>
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ flexGrow: 1 }} style={{ marginTop: 20 }}>
                <Grid container spacing={2}>
                  {[
                    {
                      title: translate(
                        "gmo",
                        "Porcentaje de cumplimiento de ventas",
                        labels["gmo-performance-advisor"]
                          ?.personal_goal_percentage?.actualName
                      ),
                      value:
                        user.employee.monthlyEmployees[0]
                          .personal_sales_compliance_percentage,
                      valueType: "percentage",
                    },
                    {
                      title: translate(
                        "gmo",
                        "Porcentaje de ventas de la tienda",
                        labels["gmo-performance-advisor"]?.store_fulfillment
                          ?.actualName
                      ),
                      value:
                        user.employee.store_codes[0].monthlyStores[0]
                          .sales_compliance_percentage,
                      valueType: "percentage",
                    },
                    {
                      title: translate("gmo", "Días del mes", null),
                      value: user.employee.monthlyEmployees[0].daysOfTheMonth,
                      valueType: "number",
                    },
                    {
                      title: translate("gmo", "Días trabajados", null),
                      value: user.employee.monthlyEmployees[0].daysWorked,
                      valueType: "number",
                    },
                    {
                      title: translate("gmo", "Bono de meta tienda", null),
                      value: user.advisor_performance_store,
                      valueType: "money",
                    },
                  ].map(({ title, value, valueType }, index) => (
                    <Grid container item xs={3}>
                      <CommisionDetailCard
                        title={title}
                        color={index % 2 === 0 ? "#F9D1D6" : "#EFFCEF"}
                        value={value}
                        valueType={valueType}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {/* BLOQUE DE Comisión por bono de gestión: */}
      {user?.gmo_rules_for_charge_id?.gmo_management_boss /*true*/ ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 0 }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">
                <strong>
                  {translate(
                    "gmo",
                    "Comisión por bono de gestión",
                    labels["gmo-management-boss"]?.bonus?.actualName
                  )}
                </strong>
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: 15, marginBottom: 30 }}>
              <CommisionDetailCard
                title={"Bono de rendimiento del jefe"}
                color={"#F9D1D6"}
                value={user.boss_performance_bonus}
                valueType={"money"}
              />
            </Grid>
            <Grid container xs={12} style={{ marginBottom: 100 }}>
              {user.employee.store_codes.map((store) => (
                <StoreCard store={store} />
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {/* BLOQUE DE Comisión por cluster boss 2024: */}
      {user?.gmo_rules_for_charge_id?.gmo_cluster_boss /*true*/ ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 0 }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">
                <strong>
                  {translate(
                    "gmo",
                    "Comisión por bono de gestión",
                    labels["gmo-management-boss"]?.bonus?.actualName
                  )}
                </strong>
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: 15, marginBottom: 30 }}>
              <CommisionDetailCard
                title={"Bono de rendimiento del jefe"}
                color={"#F9D1D6"}
                value={user.boss_cluster_bonus}
                valueType={"money"}
              />
            </Grid>
            <Grid container xs={12} style={{ marginBottom: 100 }}>
              {user.employee.store_codes.map((store) => (
                <StoreCard store={store} />
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {/* BLOQUE DE Comisión por gmo_cluster_goal_boss: */}
      {user?.gmo_rules_for_charge_id?.gmo_cluster_goal_boss /*true*/ ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 0 }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">
                <strong>
                  {translate(
                    "gmo",
                    "Comisión por bono meta",
                    labels["gmo-cluster-goal-boss"]?.bonus?.actualName
                  )}
                </strong>
              </Typography>
            </Grid>

            <Box sx={{ flexGrow: 1 }} style={{ marginTop: 20 }}>
              <Grid container spacing={2}>
                {[
                  {
                    title: translate("gmo", "Bono meta del jefe", null),
                    value: user.boss_cluster_goal_bonus,
                    valueType: "money",
                  },
                  {
                    title: translate("gmo", "Clúster", null),
                    value: user.clusterLabel,
                    valueType: "text",
                  },
                ].map(({ title, value, valueType }, index) => (
                  <Grid container item xs={6}>
                    <CommisionDetailCard
                      title={title}
                      color={index % 2 !== 0 ? "#F9D1D6" : "#EFFCEF"}
                      value={value}
                      valueType={valueType}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {/* BLOQUE DE Comisión por gmo_cluster_operating_boss: */}
      {user?.gmo_rules_for_charge_id?.gmo_cluster_operating_boss /*true*/ ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 100 }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">
                <strong>
                  {translate(
                    "gmo",
                    "Comisión por bono operativo",
                    labels["boss-cluster-operating-bonus"]?.bonus?.actualName
                  )}
                </strong>
              </Typography>
            </Grid>

            <Box sx={{ flexGrow: 1 }} style={{ marginTop: 20 }}>
              <Grid container spacing={2}>
                {[
                  {
                    title: translate("gmo", "Bono meta operativo", null),
                    value: user.boss_cluster_operating_bonus,
                    valueType: "money",
                  },
                  {
                    title: translate("gmo", "Clúster", null),
                    value: user.clusterLabel,
                    valueType: "text",
                  },
                ].map(({ title, value, valueType }, index) => (
                  <Grid container item xs={6}>
                    <CommisionDetailCard
                      title={title}
                      color={index % 2 !== 0 ? "#F9D1D6" : "#EFFCEF"}
                      value={value}
                      valueType={valueType}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {/* BLOQUE DE Comisión por consultas de optómetra: */}
      {user?.gmo_rules_for_charge_id?.gmo_inquiries_optometrist ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 0 }}
        >
          <Grid
            container
            style={{ marginBottom: 10 }}
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <Typography variant="h5">
              <strong>
                {translate(
                  "gmo",
                  "Comisión por consultas de optómetra",
                  labels["gmo-optometrist-consultations2022"]
                    ?.consultation_commission_with_purchase?.actualName +
                    "|" +
                    labels["gmo-optometrist-consultations2022"]
                      ?.consultation_commission_without_purchase?.actualName
                )}
              </strong>
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            style={{ marginBottom: 15 }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid container item xs={4}>
              <CommisionDetailCard
                title={"Consultas de optómetra"}
                color={0 % 2 === 0 ? "#F9D1D6" : "#EFFCEF"}
                value={user.optometrist_consultations}
                valueType={"money"}
              />
            </Grid>
            <Typography variant="h6" style={{ marginTop: 15 }}>
              Consultas por tienda:
            </Typography>
          </Grid>
          <Grid container xs={12} style={{ marginBottom: 10 }}></Grid>
          <Grid
            container
            xs={12}
            style={{ marginBottom: 100 }}
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            {isLoading ? (
              <p>Cargando...</p>
            ) : (
              optometristConsultations?.map((consultation, i) => (
                <Grid container item xs={6}>
                  <ConsultationDetailCard
                    storeName={consultation.store.name}
                    storeDetail={`${consultation.store.gesti_code} en ${consultation.store.city}`}
                    color={specificColors[i]}
                    withPurchase={consultation.consultation_with_purchase}
                    withoutPurchase={consultation.consultation_without_purchase}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {user?.gmo_rules_for_charge_id?.gmo_inquiries_optometrist2022 ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 0 }}
        >
          <Grid
            container
            style={{ marginBottom: 10 }}
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <Typography variant="h5">
              <strong>
                Comisión por consultas de optómetra
                {/* {translate(
                                "gmo",
                                {},
                            )} */}
              </strong>
            </Typography>
          </Grid>

          <Grid
            container
            xs={12}
            style={{ marginBottom: 15 }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box sx={{ flexGrow: 1 }} style={{ marginTop: 20 }}>
                <Grid container spacing={2}>
                  {[
                    {
                      title: translate(
                        "gmo",
                        "Comisión por consultas de optómetra",
                        labels["gmo-optometrist-consultations2022"]
                          ?.consultation_commission_with_purchase?.actualName +
                          "|" +
                          labels["gmo-optometrist-consultations2022"]
                            ?.consultation_commission_without_purchase
                            ?.actualName
                      ),
                      value: user.optometrist_consultations2022,
                      valueType: "money",
                    },
                    {
                      title: translate("gmo", "Clúster", null),
                      value: user.clusterLabel,
                      valueType: "text",
                    },
                    {
                      title: "Cumplimiento de adaptaciones en tienda más laborada",
                      value: user.adaptations_compliance_percentage_store_more_time,
                      valueType: "percentage",
                    },
                  ].map(({ title, value, valueType }, index) => (
                    <Grid container item xs={4}>
                      <CommisionDetailCard
                        title={title}
                        color={index % 2 !== 0 ? "#F9D1D6" : "#EFFCEF"}
                        value={value}
                        valueType={valueType}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>

            <Typography variant="h6" style={{ marginTop: 15 }}>
              Consultas por tienda:
            </Typography>
          </Grid>
          <Grid container xs={12} style={{ marginBottom: 10 }}></Grid>
          <Grid
            container
            xs={12}
            style={{ marginBottom: 100 }}
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            {isLoading ? (
              <p>Cargando...</p>
            ) : (
              optometristConsultations?.map((consultation, i) => (
                <Grid container item xs={6}>
                  <ConsultationDetailCard
                    storeName={consultation.store.name}
                    storeDetail={`${consultation.store.gesti_code} en ${consultation.store.city}`}
                    color={specificColors[i]}
                    withPurchase={consultation.consultation_with_purchase}
                    withoutPurchase={consultation.consultation_without_purchase}
                    contactLenses={consultation.contact_lenses}
                    progressiveLenses={consultation.progressive_lenses}
                    adaptations={consultation.adaptations}
                    subscriptions={consultation.subscriptions}
                  />
                </Grid>
              ))
            )}
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">
                <strong>
                  {translate(
                    "gmo",
                    "Comisión por rendimiento de tienda",
                    labels["gmo-performance-advisor"]?.mermas?.actualName +
                      "|" +
                      labels["gmo-performance-advisor"]?.store_fulfillment
                        ?.actualName
                  )}
                </strong>
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ flexGrow: 1 }} style={{ marginTop: 20 }}>
                <Grid container>
                  {[
                    {
                      title: "Porcentaje de ventas de la tienda",
                      value:
                        user.employee.store_codes[0].monthlyStores[0]
                          .sales_compliance_percentage,
                      valueType: "percentage",
                    },
                  ].map(({ title, value, valueType }, index) => (
                    <Grid container item xs={12}>
                      <CommisionDetailCard
                        title={title}
                        color={index % 2 === 0 ? "#F9D1D6" : "#EFFCEF"}
                        value={value}
                        valueType={valueType}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {/* BLOQUE DE Comisión por rendimiento de optómetra: */}
      {user?.gmo_rules_for_charge_id?.gmo_performance_optometrist ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 0 }}
        >
          <Grid
            container
            style={{ marginBottom: 10 }}
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <Typography variant="h5">
              <strong>Comisión por rendimiento</strong>
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            style={{ marginBottom: 15 }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid container item xs={4}>
              <CommisionDetailCard
                title={"Bono por rendimiento de optómetra"}
                color={0 % 2 === 0 ? "#F9D1D6" : "#EFFCEF"}
                value={user.optometrist_performance}
                valueType={"money"}
              />
            </Grid>
            <Typography variant="h6" style={{ marginTop: 15 }}>
              Tiendas:
            </Typography>
          </Grid>
          <Grid container xs={12} style={{ marginBottom: 10 }}></Grid>
          {user.employee.store_codes[0].monthlyStores?.map((store, i) => (
            <Grid
              container
              xs={12}
              style={{ marginBottom: 100 }}
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid container item xs={4}>
                <CommisionDetailCard
                  title={"Ventas de la tienda"}
                  color={i % 2 === 0 ? "#EFFCEF" : "#F9D1D6"}
                  value={store.store_sales}
                  valueType={"money"}
                />
              </Grid>
              <Grid container item xs={4}>
                <CommisionDetailCard
                  title={"Meta de la tienda"}
                  color={i % 2 === 0 ? "#F9D1D6" : "#EFFCEF"}
                  value={store.store_sales_goal}
                  valueType={"money"}
                />
              </Grid>
              <Grid container item xs={4}>
                <CommisionDetailCard
                  title={"Porcentaje de ventas de la tienda"}
                  color={i % 2 === 0 ? "#EFFCEF" : "#F9D1D6"}
                  value={store.sales_compliance_percentage}
                  valueType={"percentage"}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default CommisionDetailGMO;
