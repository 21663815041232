import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { FETCH_URL } from "../../../config";
import CustomDialog from "../CustomDialog/CustomDialog";
import RuleDetailForm from "./RuleDetailForm";
import { formatMoney } from "../../../utils/formatter";
import useTokenManager from "../../../hooks/useTokenManager";

const PAGE_SIZE = 5;

const valueParsers = {
  dropdown: (value) => (value === "" ? "NA" : value),
  bool: (value) => (value ? "Activada" : "Desactivada"),
  text: (value) => (value === "" ? "NA" : value),
  money: (value) => formatMoney(value),
  percentage: (value) =>
    {
      if (value < -5 || value > 5) {
        return value;
      }
      
      return (value * 100) % 1 === 0
      ? `${value * 100}%`
      : `${(value * 100).toFixed(2)}%`},
  date: (value) =>
    value
      ? new Date(value).toLocaleDateString("es-CO", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
      : "-",
};

function RulesTable({ columns, title, uri, downloadUri }) {
  const [rows, setRows] = useState([]);
  const { getToken } = useTokenManager();
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [openCreateVariableForm, setOpenCreateVariableForm] = useState(false);
  const [displayingRuleId, setDisplayingRuleId] = useState("");
  const [add, setAdd] = useState(false);
  const [openEditLabelForm, setOpenEditLabelForm] = useState(false);
  const [displayingLabel, setDisplayingLabel] = useState({});
  const [overridedColumns, setOverridedColumns] = useState(columns);
  const [filterChange, setFilterChange] = useState("");

  const handleFilterChange = (event) => {
    setFilterChange(event.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    console.log("SE MONTÓ EL COMPONENTE");
    console.log("Columns:", columns);
    console.log("Title:", title);
    console.log("URI:", uri);
    console.log("Download URI:", downloadUri);
  }, []);

  const fetchLabelNames = () => {
    const jwt = getToken();
    fetch(`${FETCH_URL}names`, { headers: { Authorization: "Bearer " + jwt } })
      .then((responseNames) => responseNames.json())
      .then((columnNames) => {
        setOverridedColumns(
          columns.map((column) => ({
            ...column,
            name: columnNames[uri][column.field]?.actualName ?? column?.name,
          }))
        );

        setIsLoading(false);
        // console.log({uri, columns, columnNames: columnNames[uri]});
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const fetchRules = () => {
    const jwt = getToken();
    setIsLoading(true);
    fetch(`${FETCH_URL}${uri}/?active=1`, {
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((response) => response.json())
      .then((d) => {
        if (uri === "gmo-management-zboss") {
          setRows(
            d.map((r) => ({
              ...r,
              id: r.gmoEmployee,
            }))
          );
        } else {
          // console.table(d);
          setRows(d);
          if (title === "Rangos") {
            setFilterChange(
              [...new Set(d.map((row) => row.variable_name))].sort()[0]
            );
          }
        }
        fetchLabelNames();
      })
      .catch((e) => {console.error(e); setIsLoading(false);});
  };

  const deleteRule = (ruleId) => {
    const jwt = getToken();
    fetch(`${FETCH_URL}${uri}/${ruleId}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + jwt,
      },
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((_) => fetchRules())
      .catch((error) => console.log("error", error));
  };

  const editRule = (ruleId) => {
    if (!ruleId) console.error("La regla es " + ruleId);
    setDisplayingRuleId(ruleId);
    setOpenCreateVariableForm(true);
  };

  const updateName = async () => {
    const jwt = getToken();
    setIsLoading(true);

    try {
      const response = await fetch(`${FETCH_URL}names`, {
        body: JSON.stringify({
          path: uri,
          variableName: displayingLabel.id,
          toUpdateName: displayingLabel.label,
        }),
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          Authorization: "Bearer " + jwt,
          "Content-Type": "application/json",
        },
      });

      fetchLabelNames();

      setOpenEditLabelForm(false);
    } catch (error) {
      console.error(error);
      setOpenEditLabelForm(false);
    }
  };

  const createRow = useCallback((row) => {
    return (
      <TableRow hover={true} role="checkbox" tabIndex={-1} key={row.code}>
        {overridedColumns.map((column) => {
          const value = row[column.field];

          return (
            <TableCell>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <p style={{ textAlign: "center" }}>
                  {valueParsers[column.type](value)}
                </p>
              </Grid>
            </TableCell>
          );
        })}
        <TableCell>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100px" }}
          >
            <Tooltip title="Modificar">
              <IconButton
                onClick={() => {
                  editRule(row.id);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar">
              <IconButton
                onClick={() => {
                  deleteRule(row.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </TableCell>
      </TableRow>
    );
  }, []);

  const editLabel = (id, currentLabel) => {
    setOpenEditLabelForm(true);
    setDisplayingLabel({ id, label: currentLabel });
  };

  useEffect(() => fetchRules(), []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            {(title === "Rangos" && rows && Array.isArray(rows) && rows.length) && (
              <Grid item>
                <FormControl variant="standard">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterChange}
                    onChange={handleFilterChange}
                  >
                    {[...new Set(rows.map((row) => row.variable_name))]
                      .sort()
                      .map((variable_name) => (
                        <MenuItem value={variable_name}>
                          {variable_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="contained"
                disabled={!downloadUri}
                onClick={() => {
                  const jwt = getToken();
                  fetch(`${FETCH_URL}${downloadUri}`, {
                    method: "GET",
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "same-origin",
                    headers: {
                      Authorization: "Bearer " + jwt,
                    },
                  })
                    .then((response) => response.blob())
                    .then((blob) => {
                      // Create blob link to download
                      const url = window.URL.createObjectURL(new Blob([blob]));
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", `Reglas ${title}.xlsx`);

                      // Append to html link element page
                      document.body.appendChild(link);

                      // Start download
                      link.click();

                      // Clean up and remove the link
                      link.parentNode.removeChild(link);
                    });
                }}
              >
                Descargar reglas históricas
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  setAdd(true);
                  setOpenCreateVariableForm(true);
                }}
              >
                Añadir regla
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ height: 60 }} />

      <Paper style={{ width: "100%" }}>
        <div style={{ height: "5px" }}>
          {isLoading ? <LinearProgress /> : <></>}
        </div>
        <TableContainer style={{ width: "85vw", height: 470 }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <colgroup>
              {overridedColumns.map((column, i) => (
                <col key={"colWidth_" + i} />
              ))}
              <col key={"colWidth_actions"} />
            </colgroup>
            <TableHead>
              <TableRow>
                {overridedColumns.map((column) => (
                  <TableCell
                    align="center"
                    key={column.id}
                    style={{ cursor: "pointer" }}
                    onClick={() => editLabel(column?.field, column?.name)}
                  >
                    {/* ES ACÁ */}
                    {column?.name}
                  </TableCell>
                ))}
                <TableCell key={"Actions_Column"}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            {
              title === "Rangos" ? (
              <TableBody style={{ height: 400 }}>
                {!rows.length && !isLoading && (
                  <TableRow hover={true} role="checkbox" tabIndex={-1}>
                    <TableCell
                      align="center"
                      colSpan={5}
                      style={{ cursor: "none" }}
                    >
                      <p>
                        No se encontraron usarios con los filtros seleccionados
                      </p>
                    </TableCell>
                  </TableRow>
                )}
                {error && (
                  <TableRow hover={true} role="checkbox" tabIndex={-1}>
                    <TableCell colSpan={5}>
                      <p>{error}</p>
                    </TableCell>
                  </TableRow>
                )}
                {rows ? rows
                  .filter((row) => row.variable_name === filterChange)
                  .slice(
                    currentPage * PAGE_SIZE,
                    currentPage * PAGE_SIZE + PAGE_SIZE
                  )
                  .map((row) => createRow(row)) : <></>
                }
              </TableBody>)
              : (<TableBody style={{ height: 400 }}>
                {rows && !rows.length && !isLoading && (
                  <TableRow hover={true} role="checkbox" tabIndex={-1}>
                    <TableCell
                      align="center"
                      colSpan={5}
                      style={{ cursor: "none" }}
                    >
                      <p>
                        No se encontraron datos con los filtros seleccionados
                      </p>
                    </TableCell>
                  </TableRow>
                )}
                {error && (
                  <TableRow hover={true} role="checkbox" tabIndex={-1}>
                    <TableCell colSpan={5}>
                      <p>{error}</p>
                    </TableCell>
                  </TableRow>
                )}
                {rows && Array.isArray(rows) && rows
                  .slice(
                    currentPage * PAGE_SIZE,
                    currentPage * PAGE_SIZE + PAGE_SIZE
                  )
                  .map((row) => createRow(row))}
              </TableBody>)
            }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          // onChangeRowsPerPage={(event) =>
          //   console.info("Cambio a filas por página de " + +event.target.value)
          // }
          component={"div"}
          count={rows?.length}
          rowsPerPage={PAGE_SIZE}
          labelRowsPerPage={"Registros por página"}
          backIconButtonText={"Anterior"}
          nextIconButtonText={"Siguiente"}
          page={currentPage}
          onChangePage={(event, newPage) => setCurrentPage(newPage)}
          labelDisplayedRows={({ from, to, count, page }) =>
            `Página ${
              page + 1
            }: mostrando de ${from} a ${to} registros de un total de ${
              count !== -1 ? count : 0
            }`
          }
        />
      </Paper>
      <CustomDialog
        title={add ? "Añadir una regla nueva" : "Modificar una regla existente"}
        open={openCreateVariableForm}
        handler={() => {
          setOpenCreateVariableForm(false);
          setDisplayingRuleId("");
          setTimeout(function () {
            setAdd(false);
          }, 500);
        }}
      >
        <RuleDetailForm
          add={add}
          ruleId={displayingRuleId}
          refresh={fetchRules}
          columns={overridedColumns}
          uri={uri}
          close={() => {
            setOpenCreateVariableForm(false);
            setDisplayingRuleId("");
          }}
        />
      </CustomDialog>
      <CustomDialog
        title={"Modificar nombre de columna"}
        open={openEditLabelForm}
        handler={() => {
          setOpenEditLabelForm(false);
          setDisplayingLabel({});
        }}
      >
        <Box
          sx={{
            maxWidth: "100%",
            marginBottom: 30,
          }}
        >
          <TextField
            fullWidth
            id="fullWidth"
            value={displayingLabel.label}
            onChange={(e) =>
              setDisplayingLabel((prevValue) => ({
                ...prevValue,
                label: e.target.value,
              }))
            }
          />
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button variant="contained" onClick={() => updateName()}>
            Guardar
          </Button>
        </Grid>
      </CustomDialog>
    </Grid>
  );
}

/*UsersInfo.propTypes = {
  token: PropTypes.string,
  users: PropTypes.arrayOf(UserInfoPropTypes),
  fetching: PropTypes.bool,
  setFilteredUser: PropTypes.fun,
  currentPage: PropTypes.number,
  size: PropTypes.number,
  numberOfRegisters: PropTypes.number,
  setQueryParams: PropTypes.fun,
};*/

export default RulesTable;
