import { Grid } from "@material-ui/core";
import RulesTable from "../../../commons/components/RulesTable/RulesTable";
import { Paper } from "@mui/material";

function ReglasPorCargo() {
  const configSettingsColumns = [
    {
      field: "name",
      name: "Nombre",
      type: "text",
    },
    {
      field: "value",
      name: "Valor",
      type: "percentage",
    },
    {
      field: "description",
      name: "Descripción",
      type: "text",
    },
    {
      field: "position",
      name: "Cargo",
      type: "dropdown",
      dropdownType: "position",
    },
    {
      field: "isActual",
      name: "Estado",
      type: "bool",
    },
  ];

  const columns = [
    {
      field: "charge",
      name: "Cargo",
      type: "text",
    },
    {
      field: "lfm_sales_advisor",
      name: "Asesor de ventas",
      type: "bool",
    },
    {
      field: "lfm_performance_advisor",
      name: "Rendimiento de asesor",
      type: "bool",
    },
    {
      field: "lfm_management_advisor_inst",
      name: "Asesor de gestión",
      type: "bool",
    },
    {
      field: "lfm_management_boss",
      name: "Jefe de tienda",
      type: "bool",
    },
    {
      field: "lfm_inquiries_boptometric",
      name: "Consultas de jefe optómetra",
      type: "bool",
    },
    {
      field: "lfm_management_zboss",
      name: "Jefe zonal",
      type: "bool",
    },
    {
      field: "lfm_inquiries_optometrist",
      name: "Consultas optómetra",
      type: "bool",
    },
    {
      field: "lfm_performance_optometrist",
      name: "Rendimiento de optómetra",
      type: "bool",
    },
    {
      field: "lfm_performance_optometrist2022",
      name: "Rendimiento de optómetra 2022",
      type: "bool",
    },
    {
      field: "lfm_inquiries_optometrist2022",
      name: "Consultas optómetra 2022",
      type: "bool",
    },
    {
      field: "lfm_inquiries_bonus_optometrist2022",
      name: "Consultas bono optómetra 2022",
      type: "bool",
    },
    {
      field: "lfm_management_boss_optometrist2022",
      name: "Consultas jefe optómetra 2022",
      type: "bool",
    },
  ];

  const lfmRangesColumns = [
    {
      field: "variable_name",
      name: "Nombre de la variable",
      type: "text",
    },
    {
      field: "label",
      name: "Etiqueta",
      type: "text",
    },
    {
      field: "greater_than",
      name: "Mayor que",
      type: "percentage",
    },
    {
      field: "lesser_than",
      name: "Menor que",
      type: "percentage",
    },
    {
      field: "result",
      name: "Resultado",
      type: "percentage",
    },
    {
      field: "with_goal",
      name: "Con meta",
      type: "bool",
    },
  ];

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Paper
        elevation={3}
        style={{
          padding: 25,
          margin: 0,
          paddingTop: 10,
          backgroundColor: "#DEEDF6",
        }}
      >
        {/* New parameter table: lfm-config-settings */}
        <div style={{ height: 10 }} />

        <RulesTable
          title="Reglas por cargo"
          columns={columns}
          uri="lfm-rules-for-charge"
          downloadUri="lfm-rules-for-charge/file"
        />
        <div style={{ height: 40 }} />
        <RulesTable
          title="Rangos"
          columns={lfmRangesColumns}
          uri="lfm-ranges"
          downloadUri="lfm-ranges/file"
        />
        <div style={{ height: 40 }} />
        <RulesTable
          title="Configuración de parámetros"
          columns={configSettingsColumns}
          uri="lfm-config-settings"
          downloadUri="lfm-config-settings/file"
        />
      </Paper>
    </Grid>
  );
}

export default ReglasPorCargo;
