import { Grid, Paper } from "@material-ui/core";
import RulesTable from "../../../commons/components/RulesTable/RulesTable";

const configSettingsColumns = [
  {
    field: "name",
    name: "Nombre",
    type: "text"
  },
  {
    field: "value",
    name: "Valor",
    type: "percentage",
  },
  {
    field: "description",
    name: "Descripción",
    type: "text"
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "isActual",
    name: "Estado",
    type: "bool"
  },
  // {
  //   field: "initial_date",
  //   name: "Fecha inicial",
  //   type: "date"
  // },
  // {
  //   field: "final_date",
  //   name: "Fecha final",
  //   type: "date"
  // }
];

const managementBossColumns = [
  {
    field: "city",
    name: "Ciudad",
    type: "dropdown",
    dropdownType: "city",
  },
  {
    field: "type_of_boss",
    name: "Tipo de Jefe",
    type: "dropdown",
    dropdownType: "typeOfBoss",
  },

  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
];

const simpleManagementBoss = [
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "percentage_sales_compliance",
    name: "percentage_sales_compliance",
    type: "percentage",
  },
  {
    field: "percentage_number_of_pieces",
    name: "Porcentaje unidades",
    type: "percentage",
  },
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
];


const clusterGoalBossColumns = [
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
  {
    field: "cluster_label",
    name: "Clúster",
    type: "text"
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "isActual",
    name: "Estado",
    type: "bool"
  },
];

const clusterOperatingBossColumns = [
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
  {
    field: "cluster_label",
    name: "Clúster",
    type: "text"
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "isActual",
    name: "Estado",
    type: "bool"
  },
];

// Variables bono operativo
const clusterOperatingBonusColumns = [
  {
    field: "portafolio",
    name: "Venta facturada",
    type: "percentage",
  },
  {
    field: "conversionCustomer",
    name: "Conversión clientes",
    type: "percentage"
  },
  {
    field: "activities",
    name: "Actividades",
    type: "percentage"
  },
  {
    field: "mermas",
    name: "Mermas",
    type: "percentage"
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "isActual",
    name: "Estado",
    type: "bool"
  }
];

function Jefes() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" style={{ width: "100%" }}>
      <Paper
        elevation={3}
        style={{
          padding: 25,
          paddingTop: 0,
          backgroundColor: "#F9D1D6",
        }}
      >
        <div style={{ height: 40 }} />
        <RulesTable
          title="Variables Bono Operativo"
          columns={clusterOperatingBonusColumns}
          uri="ray-cluster-operating-variable-boss"
          downloadUri="ray-cluster-operating-variable-boss/file"
        />

        <div style={{ height: 40 }} />
        <RulesTable
          title="Bonos Operativo"
          columns={clusterOperatingBossColumns}
          uri="ray-cluster-operating-boss"
          downloadUri="ray-cluster-operating-boss/file"
        />

        <div style={{ height: 40 }} />
        <RulesTable
          title="Bonos de Meta Jefe"
          columns={clusterGoalBossColumns}
          uri="ray-cluster-goal-boss"
          downloadUri="ray-cluster-goal-boss/file"
        />

        <div style={{ height: 10 }} />
         <RulesTable
          title="Bonos de gestión"
          columns={managementBossColumns}
          uri="ray-management-boss"
          downloadUri="ray-management-boss/file"
        />
        {/* <div style={{ height: 40 }} />
         <RulesTable
          title="Bonos de gestión (simple)"
          columns={simpleManagementBoss}
          uri="sgh-simple-management-boss"
          downloadUri="sgh-simple-management-boss/file"
        /> */}

        <div style={{ height: 10 }} />

        {/*         
        <CustomDialog
          title="Editar bono de gestión"
          open={openGestionDialog}
          handler={() => setOpenGestionDialog(false)}
        >
          <FormControl fullWidth sx={{ m: 1 }}>
            <CurrencyElement />
          </FormControl>
        </CustomDialog> */}
      </Paper>
    </Grid>
  );
}

export default Jefes;
